<template>
  <div class="event-info-video">
    <div>
      <div class="time">
        {{ timeDuration }}
      </div>
    </div>
    <div class="info-items">
      <div class="group">
        <div class="items">
          <div class="item">
            <img src="@/assets/icons/Group.svg" alt="">
            <span class="group-name">{{ getGroupName(event.user.id) }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('history_device')/*辨識設備*/" alwaysShow placement="top">
              <img src="@/assets/icons/Camera.svg" alt="">
            </TooltipElement>
            <span>{{ event.title }}({{ event.user.id }})</span>  
          </div>
          <div class="item">
            <TooltipElement :content="$t('account_label') /*帳號名稱*/" alwaysShow placement="top">
              <img src="@/assets/icons/account-ab.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="event.user?.info.name">
              <span>{{ event.user.info.name }}</span>
            </TooltipElement>
          </div>
          <div class="item">
            <TooltipElement :content="$t('account_device_model')/*設備類型*/" alwaysShow placement="top">
              <img src="@/assets/icons/Type.svg" alt="">
            </TooltipElement>
            <span>{{ event.device.info.type }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('video_type')/*影片類型*/" alwaysShow placement="top">
              <img src="@/assets/icons/FileType.svg" alt="">
            </TooltipElement>
            <span>{{ event.type }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('video_length')/*影片長度*/" alwaysShow placement="top">
              <img src="@/assets/icons/VideoTime.svg" alt="">
            </TooltipElement>
            <span>{{ formatSeconds(event.length) }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('video_size')/*影片大小*/" alwaysShow placement="top">
              <img src="@/assets/icons/File.svg" alt="">
            </TooltipElement>
            <span>{{ formatBytes(event.size) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { apiEditBoviaLprEvent } from '@/api/index.js'
import {
  formatBytes,
  formatSeconds,
  formatTime,
} from '@/utils/lib.js'

export default {
  name: 'EventInfoSos',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    event: {
      type: Object
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('account', ['groupList']),
    ...mapGetters('account', ['getUserGroupId']),
    startTime() {
      return formatTime(this.event.startTime)
    },
    endTime() {
      return formatTime(this.event.stopTime)
    },
    timeDuration() {
      let timeDuration = formatTime(this.event.startTime)
      if (this.event.stopTime) {
        timeDuration += ` ~ ${formatTime(this.event.stopTime)}`
      }
      return timeDuration
    },
  },
  methods: {
    formatBytes,
    formatSeconds,
    async setEventMisjudged(event) {
      let data = {
        event: [{id: event.id}],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await apiEditBoviaLprEvent(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
    goGoogleMap() {
      const url = `https://www.google.com.tw/maps/search/?api=1&query=${this.event.gps.latitude},${this.event.gps.longitude}`
      window.open(url, '_blank')
    },
    getGroupName(userId) {
      const groupId = this.getUserGroupId(userId)
      const group = this.groupList.find((item) => item.id === groupId)
      return group ? group.name : ''
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.event-info-video {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .time {
    display: inline-block;
    height: 34px;
    padding: 0 20px;
    border-radius: 17px;
    background: #4A5C78;
    font-size: 18px;
    font-weight: 700;
    line-height: 34px;
    margin-bottom: 8px;
    color: #ffffff;
  }

  .info-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-size: 18px;
    line-height: 36px;
  }

  .group {
    display: flex;
    column-gap: 12px;
    padding-bottom: 8px;
    &:not(:last-child) {
      border-bottom: 1px solid #ffffff33;
    }

    .items {
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      
      .item {
        display: flex;
        align-items: flex-start;
        column-gap: 8px;
        
        img {
          width: 20px;
          height: 20px;
          margin-top: 4px;
          transition: all 200ms ease-in-out;
        }
        span {
          font-size: 18px;
          line-height: 24px;
          word-break: break-all;
          color: #ffffff;

          &.group-name {
            color: #FFD133;
          }
        }
      }
    }
  }
}
</style>